import { tfjsPreloadPromise } from '../screen/photo';
import { LangCode } from '../state/lang';
import { LocalizedContent, cmsBase, loadContent } from './cms';

const preload = (url:string) => {
  // return;
  if (navigator.serviceWorker) {
    return new Promise((res) => {
      navigator.serviceWorker.ready.then((registration) => {
        if(registration.active) {
          registration.active.postMessage({type: 'preload', url});
        }
      });
      const listener = (event:MessageEvent) => {
        if(event.data.type === 'loaded' && event.data.url === url) {
          res(true);
          navigator.serviceWorker.removeEventListener('message', listener);
        }
      }
      navigator.serviceWorker.addEventListener('message', listener);
    })
  } else {
    return true;
  }
};


let audioLoaded:((v:boolean) => void)|boolean;
const audioPromises = new Promise((res) => {
  if(audioLoaded) res(true);
  audioLoaded = res;
})
export const loadAudio = async (lang: LangCode) => {
  const content = (await loadContent(lang)) as LocalizedContent; 
  if(!content) return;
  await Promise.all(content.cruiseContents.field_itinerary.map(monument => {
    return preload(`${cmsBase}${monument.field_audio.field_file.field_media_audio_file.uri.url}`)
  }))
  console.log('audio loaded')
  if(typeof audioLoaded === 'function') audioLoaded(true);
  else audioLoaded = true;
}

export const loadApp = () => Promise.all([tfjsPreloadPromise(), audioPromises]);
