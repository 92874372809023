const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){4}$/),
);

type Config = {
  onActivated?: (registration?: ServiceWorkerRegistration) => void;
};

async function registerValidSW(swUrl: string) {
  console.log(`register ${swUrl}`);
  try {
    const registration = await navigator.serviceWorker.register(swUrl);

    if (!(registration.installing || registration.waiting) && registration.active) {
      // console.log(registration)
      return registration;
    }

    registration.onupdatefound = () => {
      const serviceWorker = registration.installing || registration.waiting;
      if (serviceWorker) {
        serviceWorker.onstatechange = () => {
          console.log(`service worker state ${serviceWorker.state}`);
          if (serviceWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // At this point, the updated precached content has been fetched,
              // but the previous service worker will still serve the older
              // content until all client tabs are closed.
              console.log(
                'New content is available and will be used when all ' +
                  'tabs for this page are closed. See https://cra.link/PWA.',
              );
            } else {
              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.
              console.log('Content is cached for offline use.');
            }
          }
          if (registration.active) {
            console.log('service worker active');
            return registration;
          }
        };
      }
    };
  } catch (e) {
    console.error('Error during service worker registration:', e);
    throw e;
  }
}

async function checkValidServiceWorker(swUrl: string) {
  try {
    // Check if the service worker can be found. If it can't reload the page.
    const response = await fetch(swUrl, {
      headers: { 'Service-Worker': 'script' },
    });

    // Ensure service worker exists, and that we really are getting a JS file.
    const contentType = response.headers.get('content-type');

    if (response.status === 404 || (contentType != null && contentType.indexOf('javascript') === -1)) {
      // No service worker found. Probably a different app. Reload the page
      console.log('No service worker found. Probably a different app. Reload the page');
      navigator.serviceWorker.ready.then((registration) => {
        registration.unregister();
      });
    } else {
      // Service worker found. Proceed as normal.
      return await registerValidSW(swUrl);
    }
  } catch (e) {
    console.log('No internet connection found. App is running in offline mode.');
    throw e;
  }
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}

const registerPromise = new Promise<ServiceWorkerRegistration | undefined>((resolve, reject) => {
  if (!('serviceWorker' in navigator)) {
    console.log('no service worker in navigator');
    resolve(undefined);
    return;
  }

  // The URL constructor is available in all browsers that support SW.
  const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
  if (publicUrl.origin !== window.location.origin) {
    // Our service worker won't work if PUBLIC_URL is on a different origin
    // from what our page is served on. This might happen if a CDN is used to
    // serve assets; see https://github.com/facebook/create-react-app/issues/2374
    return resolve(undefined);
  }

  (async () => {
    const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

    try {
      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        resolve(await checkValidServiceWorker(swUrl));
      } else {
        // Is not localhost. Just register service worker
        resolve(await registerValidSW(swUrl));
      }
    } catch (e) {
      reject(e);
    }
  })();
});

export function register(config?: Config) {
  (async () => {
    const registration = await registerPromise;
    if (config && config.onActivated) {
      config.onActivated(registration);
    }
  })();
  return () => {
    config = undefined;
  };
}
