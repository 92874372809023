import { useCallback, useRef } from 'react';

import { useTranslate } from '../../state/lang';
import { useGoToScreen, burgerOpenAtom } from '../../state/app';

import styles from './styles.module.scss';
import { usePwa } from '@dotmind/react-use-pwa';
import { useAtom } from 'jotai';

const isDev = !!parseInt(process.env.REACT_APP_DEBUG || '');

function Burger() {
  const [isOpen, setIsOpen] = useAtom(burgerOpenAtom);
  const menuRef = useRef<HTMLDivElement>(null);
  const t = useTranslate();
  const goToScreen = useGoToScreen();
  const { isStandalone, isInstalled, canInstall, installPrompt } = usePwa();

  const closeMenu = useCallback(() => {
    if (isOpen) {
      menuRef.current?.classList.add(styles.closing);
      menuRef.current?.addEventListener('animationend', () => {
        setIsOpen(false);
      });
    }
  }, [isOpen, setIsOpen]);

  const handleClick = useCallback(() => {
    if (isOpen) {
      closeMenu();
    } else {
      setIsOpen(true);
    }
  }, [isOpen, closeMenu, setIsOpen]);

  const handleGoTo = useCallback(
    (screen: string) => {
      closeMenu();
      goToScreen(screen);
      setIsOpen(false);
    },
    [closeMenu, goToScreen, setIsOpen],
  );

  return (
    <div>
      <div className={`${styles.burger} ${isOpen ? styles.open : ''}`} onClick={handleClick} role={'button'}>
        <span className={styles.burger__line}></span>
        <span className={styles.burger__line}></span>
        <span className={styles.burger__line}></span>
      </div>

      {isOpen && (
        <div className={styles.burger__menu} ref={menuRef}>
          <div>
            <ul>
              <li onClick={() => handleGoTo('home')}>{t('home_nav_home')}</li>
              <li onClick={() => handleGoTo('cruise')}>
                {t('home_nav_cruise_1')} {t('home_nav_cruise_2')}
              </li>
              <li onClick={() => handleGoTo('photo')}>
                {t('home_nav_photo_1')} {t('home_nav_photo_2')}
              </li>
              <li onClick={() => handleGoTo('onboard')}>
                {t('home_nav_onboard_1')} {t('home_nav_onboard_2')}
              </li>
              <li onClick={() => handleGoTo('safe_sailing')}>{t('home_nav_safe_sailing')}</li>

              {!isInstalled && !isStandalone && canInstall && <li onClick={installPrompt}>{t('install_app')}</li>}

              {isDev && <li onClick={() => handleGoTo('tracker')}>tracker</li>}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

export default Burger;
