import { FunctionComponent, lazy, Suspense, useEffect, useMemo, useState } from 'react';
import { useAtom, useAtomValue } from 'jotai';

import { Welcome } from './screen/welcome';
import { Loading } from './screen/loading';
import AppHeader from './component/AppHeader';
import { screenAtom, useGoToScreen } from './state/app';
import { langAtom, langSelectedAtom } from './state/lang';
import { loadApp, loadAudio } from './service/preloading';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const preloadPromise = import(/* webpackChunkName: "ready-app" */ './ready-app');
const ReadyApp = lazy(() => preloadPromise as unknown as Promise<{default: FunctionComponent}>);

function App() {
  const [screen] = useAtom(screenAtom);
  const [langSelected, setLangSelected] = useAtom(langSelectedAtom);
  const goToScreen = useGoToScreen();
  const [appLoaded, setAppLoaded] = useState(false);
  const lang = useAtomValue(langAtom);

  useEffect(() => {
    const unregister = serviceWorkerRegistration.register({
      onActivated: async () => {
        console.log('sw active');

        try {
          await loadApp();
          console.log('app loaded');
        } catch (e) {
          console.error('error loading app', e)
        }
        setAppLoaded(true);
      },
    });
    return unregister;
  }, []);

  useEffect(() => {
    if(lang && langSelected) {
      loadAudio(lang);  
    }
  }, [lang, langSelected])

  const ready = langSelected && appLoaded;

  const backgroundColor = useMemo(() => {
    switch (screen.name) {
      case 'home':
      case 'welcome':
      case 'monuments':
        return '#f5f5f5';
      default:
        return '#fff';
    }
  }, [screen]);

  const dirValue = langSelected && lang === 'ar' ? 'rtl' : 'ltr';

  return (
    <div className={'App'} dir={dirValue} style={{ backgroundColor }}>
      <AppHeader ready={ready} />
      <div className={'App_Content'}>
        {!langSelected && (
          <Welcome
            onReady={() => {
              setLangSelected(true);
              goToScreen('home');
            }}
          />
        )}
        {langSelected && !appLoaded && <Loading />}
        {ready && (
          <Suspense fallback={<Loading />}>
            <ReadyApp />
          </Suspense>
        )}
      </div>
    </div>
  );
}

export default App;
