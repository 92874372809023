
export const isInternetConnected = async () => {
  try {
    const response = await fetch('/ping.txt', { method: 'HEAD', cache: 'no-store' });
    console.log(response);
    return response.ok;
  } catch (error) {
    return false; 
  }
}

// export const isInternetConnected = () => {
//   if (typeof navigator !== 'undefined' && 'onLine' in navigator && !navigator.onLine) {
//     return false;
//   } return true;
// }