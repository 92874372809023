import { useRef, useEffect, useState, useCallback } from 'react';
import { SupportedModels, createDetector, movenet, PoseDetector } from '@tensorflow-models/pose-detection';
import * as tensorflow from '@tensorflow/tfjs-core';
import '@tensorflow/tfjs-backend-webgl';
import { Trans } from '../../component/trans';
import { draw } from '../../service/photo-ar-pack';

import styles from './styles.module.scss';
import hat1 from '../../imgs/pack-ar/1-hat.png';
import hat2 from '../../imgs/pack-ar/2-hat.png';
import Loader from '../../component/Loader';
import Button from '../../component/Button';
import { useTranslate } from '../../state/lang';
import { CloseButton } from '../../component/CloseButton';
import { formatDate } from '../../service/date';

const isDev = !!parseInt(process.env.REACT_APP_DEBUG || '');

const hats = [hat1, hat2];

enum State {
  NOT_READY,
  CAPTURED,
  SAVED,
  LIVE,
}

/**
 * init tensor flow
 */
if (isDev) tensorflow.enableDebugMode();
else tensorflow.enableProdMode();
export const tfjsPreloadPromise = async () => {
  console.log('tf preload');
  await tensorflow.setBackend('webgl');
  await tensorflow.ready();
  console.log('tf ready');
  const tfjsDetectorPromise = createDetector(SupportedModels.MoveNet, {
    modelType: movenet.modelType.MULTIPOSE_LIGHTNING,
    modelUrl: '/tfjs-model/model.json',
  });
  tfjsDetectorPromise.catch((e) => console.error('error create detector', e));
  tfjsDetectorPromise.then((tf) => {
    console.log('tf inited');
    tf.dispose();
  });
  return tfjsDetectorPromise;
};
/**
 *
 */

export const Photo = () => {
  const [error, setError] = useState<string | null>();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const videoWidth = 1080; //window.innerWidth * 2;
  const videoHeight = 1080; //window.innerHeight * 2;
  const rafRef = useRef<number>();
  const [device, setDevice] = useState<number | undefined>();
  const [devices, setDevices] = useState<Array<MediaDeviceInfo> | undefined>();
  const tfjsDetectorRef = useRef<PoseDetector>();
  // pack state froce render
  const [pack, setPackState] = useState<number>();
  const packRef = useRef<number>();
  const [showCam, setShowCam] = useState(false);
  const [state, setState] = useState<State>(State.NOT_READY);
  const [capturedAt, setCapturedAt] = useState<Date>();
  const t = useTranslate();
  const [mirror, setMirror] = useState<boolean>();

  const setPack = (i: number) => {
    setPackState(i);
    packRef.current = i;
  };

  const pauseDetection = useCallback(() => {
    if (rafRef.current) {
      window.cancelAnimationFrame(rafRef.current);
      rafRef.current = undefined;
    }
  }, []);

  const runFaceDetect = useCallback(async () => {
    // console.log('runFaceDetect', videoRef.current || 'no video' , canvasRef.current ||'no canvas')
    if (videoRef.current && canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d');
      // console.log(ctx || 'no ctx')
      if (!ctx) return;
      // console.log(videoRef.current.videoWidth || 'no width', !videoRef.current.videoHeight || 'no height')
      if (!videoRef.current.videoWidth || !videoRef.current.videoHeight) return;

      canvasRef.current.width = videoRef.current.videoWidth;
      canvasRef.current.height = videoRef.current.videoHeight;
      const w = (videoRef.current.videoWidth * canvasRef.current.clientHeight) / videoRef.current.videoHeight;
      canvasRef.current.style.width = `${w}px`;
      ctx.resetTransform();
      ctx.drawImage(
        videoRef.current,
        0,
        0,
        videoRef.current.videoWidth,
        videoRef.current.videoHeight,
        0,
        0,
        videoRef.current.videoWidth,
        videoRef.current.videoHeight,
      );
      if (tfjsDetectorRef.current && packRef.current !== undefined) {
        const detector = tfjsDetectorRef.current;
        tfjsDetectorRef.current = undefined;
        try {
          const detections = await detector.estimatePoses(videoRef.current, {
            flipHorizontal: false,
            maxPoses: 3,
          });
          if (ctx && detections && packRef.current !== undefined) {
            detections.forEach((p) => draw(p, ctx, packRef.current as number));
          }
        } catch (e) {
          console.error(e);
        }
        if (!tfjsDetectorRef.current) tfjsDetectorRef.current = detector;
      }
    }
    rafRef.current = window.requestAnimationFrame(runFaceDetect);
  }, []);

  const stopDetection = useCallback(() => {
    pauseDetection();
    if (tfjsDetectorRef.current) {
      try {
        tfjsDetectorRef.current.dispose();
      } catch (e) {
        console.error(e);
      }
      tfjsDetectorRef.current = undefined;
    }
    setState(State.NOT_READY);
  }, [pauseDetection]);

  const startDetection = useCallback(() => {
    // inited
    if (tfjsDetectorRef.current) {
      setState(State.LIVE);
      runFaceDetect();
      return;
    }

    const detectorPromise = createDetector(SupportedModels.MoveNet, {
      modelType: movenet.modelType.MULTIPOSE_LIGHTNING,
      modelUrl: '/tfjs-model/model.json',
    });

    detectorPromise.then(async (detector) => {
      if (!detector) return;
      tfjsDetectorRef.current = detector;
      // init models
      if (videoRef.current) {
        try {
          console.log('estimate');
          await detector.estimatePoses(videoRef.current);
        } catch (e) {
          console.log('erreur init detector', e);
        }
      }
      setState(State.LIVE);
      runFaceDetect();
    });
  }, [runFaceDetect]);
  
  const retrieveDevices = async () => {
    let stream;
    try {
      // ask for permission
      stream = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: true
      });
    } catch (e) {
      setError('Error retriving devices');
      return;
    }
    try {
      const mediaDevices: MediaDeviceInfo[] = await navigator.mediaDevices.enumerateDevices();
      const cameras = mediaDevices.filter(({ kind }) => kind === 'videoinput');
      setDevices(cameras);
      if (cameras.length) setDevice(0);
      else setError('No camera device');
    } catch (e) {
      console.log('Error retriving devices', e);
      setError('Error retriving devices');
    }
    stream.getTracks().forEach((track) => {
      track.stop();
    });
  };

  useEffect(() => {
    if(devices){
      console.log('devices updated :')
      console.log(devices);
    }
  }, [devices, device]);

  // get devices
  useEffect(() => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      setError('Browser API navigator.mediaDevices.getUserMedia not available');
      return;
    }
    // to be sure there is camera
    retrieveDevices();
    return stopDetection;
  }, [stopDetection]);

  // start streaming device
  useEffect(() => {
    let videoStream: HTMLVideoElement;
    let stream: MediaStream;

    const stopStreamTracks = (streamMedia: MediaStream) => {
      stopDetection();
      tfjsDetectorRef.current?.dispose();
      setDevice(undefined);
      setDevices(undefined);
      streamMedia.getTracks().forEach((track, i) => {
        console.log('stop', i)
        track.stop()
        track.enabled = false
        streamMedia.removeTrack(track);
        console.log(track)
      });
      console.log(stream);
      console.log('Every track has been stopped')
    }

    if (device !== undefined && devices) {
      (async () => {
        try {
          stream = await navigator.mediaDevices.getUserMedia({
            audio: false,
            video: {
              width: videoWidth,
              height: videoHeight,
              deviceId: devices[device].deviceId,
              frameRate: {
                ideal: 30,
              },
            },
          });

          if (!videoRef.current) return;

          if (videoRef.current.srcObject) {
            (videoRef.current.srcObject as MediaStream)
              .getTracks()
              .forEach((track) => {
                track.enabled = false
                track.stop()
              })
          }

          console.log('update srcObject');
          videoRef.current.srcObject = stream;
          setMirror(stream.getVideoTracks()[0].getSettings().facingMode === 'user');
          videoRef.current.playsInline = true;
          videoStream = videoRef.current;
          videoRef.current.setAttribute('muted', '');

          try {
            videoRef.current.play();
          } catch (e) {
            // reload component can stop play
            return;
          }
          videoRef.current.addEventListener(
            'canplay',
            () => {
              if (canvasRef.current && videoRef.current) {
                const height = canvasRef.current.clientHeight;
                const width = (videoRef.current.videoWidth / videoRef.current.videoHeight) * height;
                console.log('canplay', width, height);
                videoRef.current.setAttribute('width', '' + width);
                videoRef.current.setAttribute('height', '' + height);
                canvasRef.current.setAttribute('width', '' + width);
                canvasRef.current.setAttribute('height', '' + height);
                startDetection();
              }
            },
            false,
          );
          videoRef.current.onloadedmetadata = () => {
            if (!videoRef.current || !canvasRef.current) return;
            const height = canvasRef.current.clientHeight;
            const width = (videoRef.current.videoWidth / videoRef.current.videoHeight) * height;
            videoRef.current.setAttribute('width', '' + width);
            videoRef.current.setAttribute('height', '' + height);
            canvasRef.current.setAttribute('width', '' + width);
            canvasRef.current.setAttribute('height', '' + height);
          };
        } catch (e) {
          console.error('error getting camera', e);
          setError('error getting camera');
        }
        // }
      })();
    }

    return () => {
      // console.log('videoStream:', videoStream)
      //if videoStreamAlreadyDefined
      if (videoStream?.srcObject) {
        // disable cam
        stopStreamTracks(stream);
        videoStream.srcObject = null;
        console.log('unmounted')
      } else console.log('mounting')
    };
  }, [device, devices, startDetection, videoHeight, videoWidth, stopDetection]);
  // disable cam


  const switchDevice = () => {
    if (device === undefined || devices === undefined) return null;
    pauseDetection();
    setState(State.NOT_READY);
    setDevice((device + 1) % devices.length);
  };

  const capture = useCallback(() => {
    setState(State.CAPTURED);
    setCapturedAt(new Date());
    pauseDetection();
  }, [pauseDetection]);

  if (error) {
    return (
      <p>
        <Trans>photo_no_webcam</Trans>
      </p>
    );
  }

  const PhotoLoader = () => <Loader text={t('photo_lab_loading')} />;

  if (devices === undefined) {
    return <PhotoLoader />;
  }

  const mirrorClass = mirror ? styles.mirror : '';

  // console.log('render');

  return (
    <>
      {state === State.NOT_READY && <PhotoLoader />}
      <div className={styles.container} style={{ visibility: state === State.NOT_READY ? 'hidden' : 'visible' }}>
        <canvas ref={canvasRef} className={`${styles.canvas} ${mirrorClass}`} />

        {/* live controls */}
        {state === State.LIVE && (
          <>
            {devices.length > 1 && <button onClick={switchDevice} className={styles.switchBtn} />}

            <div className={`${styles.controls} ${styles.packBtns}`}>
              <div className={`${styles.controlsContainer}`}>
                <button className={`${styles.camBtn} ${pack ? styles[`pack${pack}Btn`] : ''}`} onClick={capture}>
                  {pack && <img src={hats[pack - 1]} alt={''} />}
                </button>
                {hats.map((hat, hatIndex) => {
                  if (pack === hatIndex + 1) return null;
                  return (
                    <button key={hatIndex} onClick={() => setPack(hatIndex + 1)} className={styles[`pack${hatIndex + 1}Btn`]}>
                      <img src={hat} alt={''} />
                    </button>
                  );
                })}
              </div>
            </div>

            {isDev && (
              <button onClick={() => setShowCam(!showCam)} style={{ position: 'absolute', bottom: 10, left: 100 }}>
                cam
              </button>
            )}
          </>
        )}

        {state === State.CAPTURED && capturedAt && canvasRef.current && (
          <div className={`${styles.controls} ${styles.downloadBtn}`}>
            <Button
              callback={() => setState(State.SAVED)}
              href={canvasRef.current.toDataURL('image/jpeg', 1)}
              props={{ download: `Croisière-bateaux-parisiens-${formatDate(capturedAt)}.jpg` }}>
              {t('photo_save_preview')}
            </Button>
          </div>
        )}

        {state === State.SAVED && (
          <div className={`${styles.controls} ${styles.savedBtn}`}>
            <Button callback={() => {}}>{t('photo_saved')}</Button>
          </div>
        )}

        {[State.SAVED, State.CAPTURED].includes(state) && <CloseButton onClick={startDetection} className={styles.closeBtn} />}

        <video
          id={'video'}
          ref={videoRef}
          className={`${styles.video} ${mirrorClass}`}
          style={{ display: showCam ? 'block' : 'none' }}
        />
      </div>
    </>
  );
};
