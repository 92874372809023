import { atom, useAtom, useSetAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { useEffect } from 'react';

export type ParamsBag = { [key: string]: unknown };

export type Screen = {
  name: string;
  params: ParamsBag;
};

/**
 * SCREEN NAVIGATION
 */
// export const screenAtom = atomWithStorage<Screen>('screen', {name: 'welcome', params: {}})
export const screenAtom = atom<Screen>({ name: 'home', params: {} });

export const useGoToScreen = () => {
  const [screen, setScreen] = useAtom(screenAtom);

  return (name: string, params: ParamsBag = {}) => {
    window.history.pushState({ name, params }, '', `#${name}`); //${JSON.stringify(params)}
    console.log('push state', screen);
    setScreen({ name, params });
  };
};

export const useGoBack = () => {
  return () => {
    window.history.back();
  };
};

export const usePopStateListener = () => {
  const setScreen = useSetAtom(screenAtom);

  const onPopState = (e: PopStateEvent) => {
    console.log('pop state', e.state);
    if (e.state) {
      setScreen({
        name: e.state.name,
        params: { ...e.state.params, back: true },
      });
    } else {
      setScreen({
        name: 'home',
        params: {},
      });
      console.log('try to pop an empty state');
    }
  };

  return useEffect(() => {
    window.addEventListener('popstate', onPopState);
    return () => {
      window.removeEventListener('popstate', onPopState);
    };
  });
};

if (window.history.scrollRestoration) {
  window.history.scrollRestoration = 'manual';
}

/**
 * NAV BURGER STATE
 */
export const burgerOpenAtom = atom(false);

/**
 * ws refreshed
 */
export const wsReloadedAtom = atomWithStorage<boolean>('ws_relaod', false);
