/* eslint-disable max-len */
import { LangCode } from '../state/lang';
import Jsona from 'jsona';
import { canMatchType } from '../utils';
import jsonpath from 'jsonpath';

const dataFormatter = new Jsona();

export const cmsBase = process.env.REACT_APP_API_CMS_BASEURL || '';

// eslint-disable-next-line max-len
export const cruiseContentEndpoint = `itinerary?include=${[
  'field_itinerary',
  'field_itinerary.field_quizz_advanced',
  'field_itinerary.field_quizz_advanced.field_answers',
  'field_itinerary.field_quizz_family',
  'field_itinerary.field_quizz_family.field_answers',
  'field_itinerary.field_image_monument',
  'field_itinerary.field_image_thumbnail',
  'field_itinerary.field_audio',
  'field_itinerary.field_audio.field_file',
  'field_itinerary.field_audio.field_thumbnail',
  'field_itinerary.field_audio.field_file.field_media_audio_file',
  'field_itinerary_map',
].join()}&fields[file--file]=uri,url,filename,image_style_uri`;

// eslint-disable-next-line max-len
const onBoardServicesEndpoint = `onboard_services?include=${[
  'field_dock_service_section',
  'field_dock_service_section.field_dock_service_image',
  'field_onboard_services_section',
  'field_onboard_services_section.field_service_pitcto_image',
].join()}`;

type Body = {
  processed: string;
  value: string;
};

export type ContentPage = {
  body: Body;
  langcode: LangCode;
  id: string;
  type: string;
  title: string;
};

export type FieldAnswer = {
  id: string;
  field_proposition: string;
  field_truthful: boolean;
};

export type FieldQuestion = {
  field_answers: FieldAnswer[];
  field_explanation: string;
  field_question_title: string;
  id: string;
};

export type FieldImage = {
  id: string;
  langcode: LangCode;
  filename: string;
  uri: { url: string };
  image_style_uri: {
    audio: string;
    monument_thumbnail: string;
  };
};

type MediaAudio = {
  name: string;
  field_media_audio_file: {
    filename: string;
    uri: { url: string };
  };
};
export type FieldAudio = {
  field_file: MediaAudio;
  field_wysiwyg: { value: string };
  field_thumbnail: FieldImage;
};

export type ContentMonument = {
  langcode: LangCode;
  id: string;
  type: string;
  title: string;
  field_geolocation_point: { lat: number; lng: number };
  body: Body;
  field_quizz_advanced: FieldQuestion[];
  field_quizz_family: FieldQuestion[];
  field_image_monument: FieldImage[];
  field_image_thumbnail: FieldImage;
  field_audio: FieldAudio;
  field_side: 'left' | 'right' | 'front';
};

export type CruiseContent = {
  title: string;
  type: string;
  field_itinerary: ContentMonument[];
  field_itinerary_map: FieldItineraryMap;
};

type FieldItineraryMap = {
  id: string;
  uri: { url: string };
};

type FieldServiceImage = {
  id: string;
  filename: string;
  uri: {
    value: string;
    url: string;
  };
  filemime: string;
  filesize: number;
  resourceIdObjMeta: {
    alt: string;
    title: string;
    width: number;
    height: number;
  };
};

type DockService = {
  id: string;
  field_paragraph: string;
  field_subtitle: string;
  field_title: string;
  field_dock_service_image: FieldServiceImage;
};

type OnBoardServices = {
  id: string;
  field_picto_paragraph: string;
  field_picto_title: string;
  field_service_pitcto_image: FieldServiceImage;
};

export type CruiseServices = {
  id: string;
  title: string;
  field_dock_fin_titre: string;
  field_dock_first_title: string;
  field_onboard_end_title: string;
  field_onboard_first_title: string;
  field_dock_service_section: DockService[];
  field_onboard_services_section: OnBoardServices[];
};

export type LocalizedContent = {
  cruiseContents: CruiseContent;
  tripResponsibly: ContentPage;
  onBoardServices: CruiseServices;
};

const contentPromises: Partial<Record<LangCode, Promise<unknown>>> = {};
const contents: Partial<Record<LangCode, LocalizedContent | Promise<unknown>>> = {};

export type Content = ContentPage | CruiseContent | CruiseServices;

export type Loader = <T>(path: string) => Promise<T>;

let load: Loader = async <T>(path: string): Promise<T> => {
  let fetchTry = 5
  let decoded
  while(--fetchTry) {
    try {
      console.log(`${cmsBase}${path}`)
      const resp = await fetch(`${cmsBase}${path}`, { mode: 'cors', redirect: 'follow' });
      decoded = await resp.json()
      break
    } catch(e) {
      console.error(`error loading ${path}`, e)
    }
  }
  if(decoded) return decoded
  throw new Error(`can't load ${path} after 5 tries`)
};

// used to get content on build
export const setLoadFunction = (loader: Loader) => {
  load = loader;
};

const loadCruiseContent = async (lang: LangCode) => {
  const node = await load<{
    data: CruiseContent[];
    included: ContentMonument[];
  }>(`/${lang}/jsonapi/node/${cruiseContentEndpoint}`);
  const deserializedJson = dataFormatter.deserialize({
    data: node.data[0],
    included: node.included,
  });

  if (canMatchType<CruiseContent>(deserializedJson, ['field_itinerary', 'type', 'title'])) {
    // debug force lat / lng
    deserializedJson.field_itinerary.forEach((m, i) => {
      const forcedLat = process.env[`REACT_APP_MONUMENT_${i + 1}_LAT`];
      const forcedLng = process.env[`REACT_APP_MONUMENT_${i + 1}_LNG`];
      if (forcedLat !== undefined) {
        m.field_geolocation_point.lat = parseFloat(forcedLat);
      }
      if (forcedLng !== undefined) {
        m.field_geolocation_point.lng = parseFloat(forcedLng);
      }
    });

    // rm missing refs
    deserializedJson.field_itinerary = deserializedJson.field_itinerary.filter((m) => m.id !== 'missing');

    return deserializedJson;
  }

  throw new Error('Error@cms.ts:156:36: API response cant satisfy type exigences.');
};

const loadOnboardService = async (lang: LangCode) => {
  console.log(`/${lang}/jsonapi/node/${onBoardServicesEndpoint}`)
  const node = await load<{
    data: CruiseContent[];
    included: ContentMonument[];
  }>(`/${lang}/jsonapi/node/${onBoardServicesEndpoint}`);
  const deserializedJson = dataFormatter.deserialize({
    data: node.data[0],
    included: node.included,
  });

  if (canMatchType<CruiseServices>(deserializedJson, ['type', 'title'])) {
    return deserializedJson;
  }

  throw new Error('Error@cms.ts:172:31: API response cant satisfy type exigences.');
};

const loadTripResponsibly = async (lang: LangCode) => {
  const node = await load<{
    data: ContentPage[];
  }>(`/${lang}/jsonapi/node/trip_responsibly?page[limit]=1`);

  const deserializedJson = dataFormatter.deserialize({
    data: node.data[0],
  });

  if (canMatchType<ContentPage>(deserializedJson, ['body', 'langcode', 'id', 'type', 'title'])) {
    return deserializedJson;
  }

  throw new Error('Error@cms.ts:189:38: API response cant satisfy type exigences.');
};

export type MediaLoader = (path: string) => Promise<boolean>;

let preloadUrl: MediaLoader = () => {
  //url: string) => {
  // c'est fait par le manifest du service worker
  // const preloadLink = document.createElement('link');
  // preloadLink.href = url;
  // preloadLink.rel = 'preload';
  // preloadLink.as = /(?:wav|mp3|webm)$/.test(url) ? 'audio' : 'image';
  // // preloadLink.crossOrigin = 'anonymous';
  // document.head.appendChild(preloadLink);
  // return new Promise((resolve, reject) => {
  //   preloadLink.onload = () => {
  //     console.log(`preloaded ${url}`);
  //     resolve(true);
  //   };
  //   preloadLink.onerror = (e) => {
  //     console.log(`error preloading ${url}`);
  //     reject(e);
  //   };
  // });
  return new Promise((res) => res(true));
};

export const setPreloadUrl = (mediaLoader: MediaLoader) => {
  preloadUrl = mediaLoader;
};

const mediaLoadingPromises: Record<string, Promise<unknown>> = {};
const loadMedias = (data: object) => {
  const paths = [
    '$..field_media_audio_file.uri.url',
    '$..field_itinerary_map.uri.url',
    '$..field_image_thumbnail.image_style_uri.monument_thumbnail',
    '$..field_thumbnail.image_style_uri.audio',
    '$..field_image_monument..uri.url',
    '$..field_service_pitcto_image.uri.url',
    '$..field_dock_service_image.uri.url',
    '$..field_image_thumbnail.uri.url',
  ];
  const t = paths.map((path) => jsonpath.query(data, path) as string[]);
  const medias: string[] = new Array<string>().concat(...t);
  return Promise.all(
    medias.map((path) => {
      if (!Object.keys(mediaLoadingPromises).includes(path)) {
        mediaLoadingPromises[path] = preloadUrl(`${cmsBase}${path.replace(cmsBase, '')}`);
      }
      return mediaLoadingPromises[path];
    }),
  );
};

const loadContentForLang = (lang: LangCode) => {
  // déjà chargé ou en cours
  if (contentPromises[lang]) return contentPromises[lang];

  const loadingPromise = Promise.all([loadTripResponsibly(lang), loadCruiseContent(lang), loadOnboardService(lang)]);

  // keep track of the promise
  contentPromises[lang] = new Promise(async (resolve, reject) => {
    const [tripResponsibly, cruiseContents, onBoardServices] = await loadingPromise
    const content = { tripResponsibly, cruiseContents, onBoardServices }
    try {
      await loadMedias(content);
      console.log('success : content for language "'+lang+'" loaded successfully');
    } catch (error) {
      console.error('error loading medias', (error as Error).message, error);
      reject(error);
    }
    contents[lang] = content;
    resolve(content);
  });
  return contentPromises[lang];
};

export const loadContent = (lang: LangCode) => {
  // en prio on récupère la langue sélectionnée
  const prio = loadContentForLang(lang);

  // on va chercher le reste
  // let code: keyof typeof LangCode;
  // for (code in LangCode) {
  //   if (LangCode[code] === lang) continue;
  //   loadContentForLang(LangCode[code] as LangCode);
  // }

  return prio;
};

export const getContents = () => {
  return contents;
};
