import { useEffect, useRef } from 'react';
import crossIcon from '../../imgs/monuments/close.svg';
import styles from './styles.module.scss';


type BaseModalProps = {
  isOpen: boolean
  handleClose: () => void
  children: React.ReactNode
}

function BaseModal({ isOpen, handleClose, children }: BaseModalProps) {
  const el = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (el.current) {
      el.current.style.opacity = '1';
    }
  }, [isOpen]);

  return isOpen ? (
    <div className={`${styles.modal} ${styles.modalHeader}`}>
      <div className={styles.backdrop} />

      <div ref={el} className={styles.modal_content}>
        <button className={styles.close} onClick={() => handleClose()}>
          <img src={crossIcon} alt={'close'} />
        </button>

        <div className={styles.content}>
          { children }
        </div>
      </div>
    </div>
  ) : null;
}

export default BaseModal;